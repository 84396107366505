import React, { useEffect, useState } from 'react';
import { styled } from "styled-components";
import { primary, secondary } from '../theme/Colors';
import Projects from './Projects';
import About from './About';
import Services from './Services';
import { device } from '../theme/Breakpoints';

const Sidebar = styled.aside`
  background-color: ${primary};
  min-height: calc(100vh - 170px);
  position: relative;
  z-index: 3;
  width: 45px;

  @media ${device.laptop}{
    width: 90px;
  min-height: calc(100vh - 125px);
  }
`

const Nav = styled.nav`
    margin-left: 60px;
    padding-bottom: 24px;
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    box-sizing: border-box;
    z-index: 100;
    position: relative;
    @media ${device.laptop}{
      display: flex;
    }
  a {
    display: block;
    box-sizing: border-box;
    padding: 10px 28px;
    border-radius: 100px;
    width: 150px;
    background-color: ${secondary};
    margin-bottom: 16px;
    font-size: 24px;
    color: white;
    text-decoration: none;
    position: relative;
    &:after{
      text-decoration: underline;
      content: '';
      transform: rotate(45deg);
      height: 8px;
      width: 8px;
      display: block;
      border: 2px solid;
      border-color:transparent;
      position: absolute;
      right: 20px;
      top: 20px;
      transition: all .36s;
    }
    &:hover:after,
    &.active:after{
      border-color: white white transparent transparent;
    }
  }
`

const Wrapper = styled.figure`
  background-color: ${secondary};
  display: flex;
  flex-direction: row;
  align-items: start;
  
  position: absolute;

  left: 0;
  overflow: hidden;
  z-index: 3;
  color: ${secondary};
  
  box-sizing: border-box;
  width: 0;
  height: 0;
  min-height: 0;
  margin: 0;
  padding: 0;

  transition: border-radius .48s;

  @media ${device.mobileM}{
    border-radius: 0 120px 0 0;
    top: 100px;
  }
  @media ${device.tablet}{
    border-radius: 0 240px 0 0;  
    top: 200px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 5px;
    height: calc(100% - 14px);
    width: calc(100% - 14px);
    border-radius: inherit;
    border: 0px dashed white;
    pointer-events: none;
  }

  nav{
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  &.active {
    width: 100vw;
    height: auto;
    min-height: calc(100% - 200px );
    padding: 20px;    

    &::before{
      border: 2px dashed white;
    }
    @media ${device.mobileM}{
      padding: 40px;
      &::before{
        border: 3px dashed white;
      }
    }
    @media ${device.laptop}{
      padding-left: 230px; 
      &::before{
        border: 4px dashed white;
        left: 10px;
        top: 10px;
        height: calc(100% - 28px);
        width: calc(100% - 28px);
      }
    }

  }
`
const Overlay = ({ activePage, visible, onNav, onToggle }) => {
  const [overlayContent, setOverlayContent] = useState(<></>);

  const showOverlay = (e) => {
    onToggle(true);
    if (e.target.href) {
      onNav(e.target.getAttribute("href"));
    }
  }
  const hideOverlay = () => {
    onToggle(false);
    onNav('');
  }

  const handleNav = (e) => {
    e.preventDefault()
    showOverlay(e);
  }


  useEffect(() => {
    if (!activePage) return;
    let content;
    switch (activePage) {
      case 'gallerij':
        content = <Projects />;
        break;
      case 'prijslijst':
        content = <Services />;
        break;
      case 'over':
        content = <About />;
        break;
      default:
        content = <></>;
        break;
    }
    setOverlayContent(content);

  }, [activePage]);

  return (
    <>
      <Sidebar>
      </Sidebar>
      <Wrapper onMouseLeave={hideOverlay} className={visible ? 'active' : ''}>
        <Nav>
          <a className={activePage === "over" ? 'active' : ''} onMouseEnter={showOverlay} onClick={handleNav} href="over">Over mij</a>
          <a className={activePage === "prijslijst" ? 'active' : ''} onMouseEnter={showOverlay} onClick={handleNav} href="prijslijst">Prijslijst</a>
          <a className={activePage === "gallerij" ? 'active' : ''} onMouseEnter={showOverlay} onClick={handleNav} href="gallerij">Gallerij</a>
        </Nav>
        {overlayContent}
      </Wrapper>
    </>
  );
}

export default Overlay;