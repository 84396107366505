import React from 'react';
import styled from 'styled-components';
import Image from './Image';
import { device } from '../theme/Breakpoints';

const Article = styled.article`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: white;
  overflow: hidden;
  flex-direction: column;
  /* justify-content: space-around; */

  @media ${device.tablet}{
  width: 260px;
  }

  h2 {
    margin-top: 0;
  }
  img{
    object-fit: cover;
    object-position: center center;
  }
`;

const Img = styled(Image)`
  height: 180px;
  width: 100%;
`;

function Project({ title, image, description, onClick }) {

  return (
    <Article className="project">
      <h2>{title}</h2>
      <Img onClick={onClick} {...image.data.attributes} />
      <p> {description} </p>
    </Article>
  );
}

export default Project;