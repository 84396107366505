import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Project from "./Project";
import axios from "axios";
import { styled } from 'styled-components';
import Lightbox from './Lightbox';
import { device } from '../theme/Breakpoints';

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  
  @media ${device.laptop}{
    padding-bottom: 20px;
    margin-left: 90px;
  }

  article:nth-of-type(${props => props.$curvedItem}){
    border-radius: 0 ${props => props.radius}px 0 0;
    h2 {
      padding-right: ${props => props.radius / 2}px;
    }
    img{
    border-radius: 0 ${props => props.radius / 2}px 0 0;

    }
  }
`;


function Projects(props) {
  const ref = useRef(null);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [lightboxImg, setLightboxImg] = useState('')
  const [lightboxVisible, setLightboxVisible] = useState(false)
  const [width, setWidth] = useState(0);

  const itemsPerRow = document.documentElement.clientWidth > 767 ? Math.floor((width + 20) / 280) : 1;
  let radius = 200 - ((width - ((itemsPerRow - 1) * 280)) - 260);

  radius = document.documentElement.clientWidth < 768 ? 120 : radius;
  radius = document.documentElement.clientWidth > 374 ? radius : 0;
  radius = radius < 0 ? 0 : radius;
  useEffect(() => {
    setWidth(document.documentElement.clientWidth - 360);

    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/projects?populate=*`)
      .then(({ data }) => setProjects(data.data))
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    const rc = ref.current;
    window.addEventListener("resize", () => {
      setWidth(rc.offsetWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWidth(rc.offsetWidth);
      })
    }
  }, [ref]);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, []);

  const showLightbox = (e) => {
    setLightboxImg(e.target.src)
    setLightboxVisible(true);
  }

  const hideLightbox = () => {
    setLightboxImg('')
    setLightboxVisible(false);
  }

  if (error) {
    return <div>An error occured: {error.message}</div>;
  }
  return (
    <>
      <Section ref={ref} radius={radius} $curvedItem={itemsPerRow}>
        {projects.map(({ id, attributes: props }) => (
          <Project onClick={showLightbox} key={id} {...props} />
        ))}
      </Section>
      <Lightbox visible={lightboxVisible} src={lightboxImg} onClick={hideLightbox} />
    </>
  );
}

export default Projects;