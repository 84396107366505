import axios from "axios";
import { styled, createGlobalStyle } from "styled-components";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Header from "./components/Header";
import Overlay from "./components/Overlay";
import Footer from "./components/Footer";
import Slider from "./components/Slider";

import { primary } from "./theme/Colors";
import Alert from "./components/Alert";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    background-color: ${primary};
    color: white;
  }

  p{
    max-width: 640px;
  }

  figure{
    margin: 0;
    padding: 0;
  }
`
const Main = styled.main`
  background-color: ${primary};
  display: flex;
  flex-direction: row;
  position: relative;
  ${props => props.$mobileNavVisible &&`
      overflow: hidden;
      max-height: 100px;
    `
  }
`;

const helmetContext = {};

const App = () => {
  const [error, setError] = useState(null);
  const [frame, setFrame] = useState(null);
  const [activePage, setActivePage] = useState('');
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [mobileNavVisible, setMobileNavVisible] = useState(false);

  const onNav = (page) => {
    setActivePage(page)
  }

  const onMobileNav = (page) => {
    onNav(page);
    setOverlayVisible(!!page);
  }

  const toggleOverlay = (v) => {
    setOverlayVisible(v);
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/frame?populate=*`)
      .then(({ data }) => setFrame(data.data.attributes))
      .catch((error) => setError(error));
  }, []);
  return (
    <HelmetProvider context={helmetContext}>
      <GlobalStyle />
      <Helmet>
        <title>Margreth creatie</title>
      </Helmet>
      {frame && <>
        <Alert {...frame.alert} />
        <Header 
          slogan={frame?.slogan || error} 
          logo={frame?.logo} 
          activePage={activePage}
          onNav={onMobileNav}
          navVisible={mobileNavVisible}
          onNavToggle={setMobileNavVisible}
          contact={frame?.contact} 
          socials={frame?.socials}
        />
        <Main $mobileNavVisible={mobileNavVisible}>
          <Overlay activePage={activePage} onNav={onNav} visible={overlayVisible} onToggle={toggleOverlay} />
          <Slider />
        </Main>
        <Footer contact={frame?.contact} socials={frame?.socials} visible={!overlayVisible} />
      </>}
    </HelmetProvider>
  );
};

export default App;