import React from 'react';
import { styled } from 'styled-components';
import { primary, secondary } from '../theme/Colors';
import Image from './Image';
import { differenceInYears } from 'date-fns';
import { device } from '../theme/Breakpoints';
import { MdClose, MdMenu } from 'react-icons/md';
import Footer from './Footer';

const Wrapper = styled.header`
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: start;
  background-color: ${primary};
  gap: 20px;
  height: 170px;

  @media ${device.laptop}{
    flex-direction: row;
    justify-content: flex-start;
    align-items: end;

    height: 125px;
  }
  h1{
    padding: 0 0 20px;
    margin: 0 20px;
    font-size: 18px;
    color: white;
    font-weight: normal;
    
    @media ${device.laptop}{
      font-size: 21px;
      margin-left: auto;
    }
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media ${device.laptop}{
      width: auto;
      height: 125px;
      align-items: initial;
    }
  }
`;

const Logo = styled(Image)`
  max-width: 160px;
  margin: 20px 0 0 20px;
  cursor: pointer;
  user-select: none;
  @media ${device.laptop}{
    max-height: 100px;
    max-width: none;
    margin: 20px 0 0 60px;
  }
`

const MenuButton = styled.button`
  background-color: ${secondary};
  height: 40px;
  width: 40px;
  border-radius: 40px;
  border: 0px solid;
  margin: 20px 20px 0 0;
  padding: 0;
  color: white; 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .24s;
  svg{
    height: 24px;
    width: 24px;
  }
  &:hover{
    box-shadow: 0 0 0 2px white;
  }
  @media ${device.laptop}{
    display: none;
  }

`;

const MobileNav = styled.nav`
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  gap: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${secondary};
  z-index: 5;

  a {
    display: block;
    box-sizing: border-box;
    padding: 0 10px;
    width: 100%;
    margin: 0 auto;
    font-size: 24px;
    color: white;
    text-decoration: none;
    position: relative;
    text-align: center;
    height: 60px;
    line-height: 60px;
    &:hover, &.active{
      background-color: ${primary};
    }
  }

  ${MenuButton}{
    position: absolute;
    top: 12px;
    right: 0;
    background-color: ${primary};
  }

  ${Logo}{
      position: absolute;
      top: 0px;
      left: 0;
  }

  @media ${device.laptop}{
    display: none;
  }
`;

function Header({ slogan, logo, activePage, onNav, socials, contact, navVisible, onNavToggle }) {
  const timedSlogan = slogan?.replace('...', differenceInYears(new Date(), new Date(2011, 9, 19)));

  const toggleNav = () => {
    onNavToggle(!navVisible);
  }

  const handleNav = (e) => {
    e.preventDefault()
    onNav(e.target.getAttribute('href'));
    onNavToggle(!navVisible);
  }
  const handleHome = () => {
    onNav('');
    onNavToggle(false);
  }

  const preventScroll = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <Wrapper>
        <div>
          <Logo {...logo.data.attributes} onClick={handleHome} />
          <MenuButton onClick={toggleNav}><MdMenu /></MenuButton>
        </div>
        <h1>{timedSlogan}</h1>
      </Wrapper>
      <MobileNav $visible={navVisible} onScroll={preventScroll}>
        <Logo {...logo.data.attributes} onClick={handleHome} />

        <a className={activePage === "over" ? 'active' : ''} onClick={handleNav} href="over">Over mij</a>
        <a className={activePage === "prijslijst" ? 'active' : ''} onClick={handleNav} href="prijslijst">Prijslijst</a>
        <a className={activePage === "gallerij" ? 'active' : ''} onClick={handleNav} href="gallerij">Gallerij</a>
        <MenuButton onClick={toggleNav}><MdClose /></MenuButton>
        <Footer socials={socials} contact={contact}/>
      </MobileNav>
    </>

  );
}

export default Header;