import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from './Image';
import { primary } from '../theme/Colors';
import { device } from '../theme/Breakpoints';

const ServiceRow = styled.tr`
  border-collapse: collapse;
  td {
    border: 1px solid;
    padding: 8px;
  }
`
const Wrapper = styled.section`
  background-color: white;
  width: 100%;
  border-radius: 45px;
  border-top-right-radius: inherit;
  padding: 20px 0;

  @media ${device.tablet}{
    margin-left: 45px;
  }
  @media ${device.laptop}{
    margin-left: 90px;
    padding-bottom: 45px;
  }
`;

const Category = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  & + & {
    padding-top: 25px;
    border-top: 2px dashed ${primary};
  }
  
  @media ${device.tablet}{
    padding-left: 0;
    margin-top: 45px;

    align-items: start;
    padding-right: 45px;
    flex-direction: row;
    & + & {
      padding-top: 25px;
      border-top: none;
    }
  }

  h2, h3 {
    text-transform: capitalize;
  }
  figure{
    background-color: white;
    padding: 15px;
    position: relative;
    width: 190px;
    height: 290px;
    border: 3px solid ${primary};
    border-radius: 25px 25px 0 0;
    text-align: center;
    flex-shrink: 0;
    @media ${device.tablet}{
      left: -50px;
      margin-left: 0;
    }
  }

  article{
    padding: 0 20px 20px;
  }

  img{
    max-height: 200px;
  }

  p{
    overflow: hidden;
    position: relative;
    @media ${device.tablet}{
      padding-right: 60px;
    }
  }

  table {
    border-collapse: collapse;
  }
`
const formatPriceLists = lists => {
  let groupedLists = {};
  lists.forEach(({ attributes: list }) => {
    const category = list.category.data.attributes.name;

    if (!groupedLists[category]) {
      groupedLists[category] = { image: list.category.data.attributes.image.data.attributes, lists: [list] };
    } else {
      groupedLists[category].lists.push(list);
    }
  })

  return groupedLists;
}

const Euro = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

const PriceList = ({ description, services, title }) => {
  return (
    <article>
      {title && <h3>{title}</h3>}
      {description && <p>{description}</p>}
      <table>
        <tbody>
          {services.map(({ name, price }, i) => {
            return (
              <ServiceRow key={i}>
                <td>{name}</td>
                <td>{price ? Euro.format(price) : 'Op aanvraag'}</td>
              </ServiceRow>
            )
          }
          )}
        </tbody>
      </table>
    </article>
  );
}

const PriceCategory = ({ name, lists, image }) => {
  return (
    <Category>
      <figure>
        <Image {...image} />
        <h2>{name}</h2>
      </figure>
      <div>

        {lists.map((list, i) => {
          return <PriceList key={i} {...list} />
        })}
      </div>
    </Category>
  )
}

const Services = () => {
  const [error, setError] = useState(null);
  const [priceLists, setPriceLists] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/pricelists?populate=deep`)
      .then(({ data }) => setPriceLists(formatPriceLists(data.data)))
      .catch((error) => setError(error));
  }, []);


  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }
  return (
    <Wrapper>
      {Object.entries(priceLists).map(([name, { image, lists }], i) => {
        return <PriceCategory key={i} name={name} image={image} lists={lists} />
      })}
    </Wrapper>
  )



}

export default Services;