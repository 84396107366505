import styled from "styled-components";
import { device } from "../theme/Breakpoints";

const GM = styled.iframe`
  flex: 1;
  height: 450px;
  border: none;
  position: relative;
  width: 200px;

  @media ${device.tablet}{
    width: auto;
    min-width: 450px;
    margin-left: 0;
    margin-right: 0;
  }
` 

const Map = () => {
  return (
    <GM
      loading="lazy"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBHJkAAZ8u5Ep1kUOk4BjUnAMxcE-f2xgg
    &center=51.8195554,4.4192082&zoom=17&language=nl-NL&q=Croonenburgh%2075">
    </GM>
  )
}

export default Map;