import { isAfter, isBefore, isWithinInterval } from 'date-fns';
import React from 'react';
import styled, { keyframes } from 'styled-components'

const slideIn = keyframes`
 0% { top: -40px; opacity:0;}
 100% { top:0; opacity: 1; }
`
const Dialog = styled.dialog`
  position: fixed;
  width: 100%;
  background-color: #c6efff;
  color: black;
  text-shadow: none;
  top:0;
  display: block;
  border: none;
  z-index: 99999999999;
  text-align: center;
  animation-name: ${slideIn};
  animation-duration: 1.2s;
  animation-iteration-count: 1;
`

function Alert({ message, from, until }) {
  let showAlert = true;

  if(!message) return;
  else if(from && until){
    showAlert = isWithinInterval(new Date(),{
      start: new Date(from),
      end: new Date(until)
    })
  }
  else if(from){
    showAlert = isAfter(new Date(), new Date(until));
  }
  else if(until){
    showAlert = isBefore(new Date(), new Date(until));
  }

  if (showAlert ){
    return (
      <Dialog>
        {message}
      </Dialog>
    );
  }
  
  return;
}

export default Alert;