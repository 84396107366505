import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Image from './Image';
import { device } from '../theme/Breakpoints';

const Wrapper = styled.figure`
  background-color:white;
  flex: 1;
  overflow: hidden;
  margin: 0;
  position: relative;
  z-index: 1;
  border-radius: 150px 0 0 0;
  @media ${device.laptop}{
    border-radius: 300px 0 0 0;
  }
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transition: opacity 1.2s;
    opacity: 0;
    object-fit: cover;
    object-position: center center;
    filter: grayscale(1);
    &.active{
      opacity: 1;
    }
  }
`

function Slider(props) {

  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(0);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/projects?populate=*&filters[featured][$eq]=true`)
      .then(({ data }) => setProjects(data.data))
      .catch((error) => setError(error));
  }, []);

  const nextProject = useCallback(()=>{
    return activeProject !== projects.length - 1 ? activeProject + 1 : 0
  },[projects,activeProject])

  useEffect(() => {
    if(projects.length){
      const interval = setInterval(() => {
        setActiveProject(nextProject())
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [nextProject,projects]);

  return (
    <Wrapper>
        {!error && projects.map(({ id, attributes: props }) => (
            <Image key={id} className={id - 1 ===activeProject ?'active':''} {...props.image.data.attributes} />
        ))}
        {/* TODO: add controls */}
    </Wrapper>
  );
}

export default Slider;