import React from 'react';
import styled from 'styled-components';
import { primary } from '../theme/Colors';

const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: ${primary}88;
  padding: 40px;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: all .48s;
  &.visible {
    pointer-events: all;
    opacity: 1;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
  
`

function Lightbox({visible, src, onClick}) {

  return (
    <Overlay className={visible ? 'visible':''} onClick={onClick}>
      <img src={src} alt=''/>
    </Overlay>
  );
}

export default Lightbox;