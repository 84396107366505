import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import Map from './Map';
import { differenceInYears } from 'date-fns';
import Image from './Image';
import { device } from '../theme/Breakpoints';

const Wrapper = styled.section`
  background-color: white;
  width: 100%;
  border-radius: 45px;
  border-top-right-radius: inherit;
  padding: 20px;
  @media ${device.mobileL}{
  padding: 45px;
}
  @media ${device.tablet}{
    margin-left: 45px;
  }
  @media ${device.laptop}{
    margin-left: 90px;
  }
`

const Intro = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: 640px){
    margin-bottom: 50px;
    flex-direction: row;
  } 
  img{
    width: 190px;
    object-fit: cover;
    position: relative;
    border: 3px solid white;
    margin-right: 20px;
    border-radius: 25px 25px 0 0;
    @media ${device.tablet}{
      left: -95px;
      margin-right: -50px;
    }
  }
  p{
    max-width: auto;
    overflow: hidden;
    position: relative;
  }
`

const Contact = styled.address`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap:wrap;
  gap: 50px;
  font-style: normal;
  h4+h4{
    margin-top: -1em;
  }
  dl {
    max-width: 240px;
    justify-content: space-between;
    display: flex;
    gap:5px;
    flex-wrap: wrap;
    @media ${device.mobileL}{
      gap: 5px 20px;
    }
  }
  dd{
    text-align: right;
    margin: 0;
    width: 100px;
  }
  dt{
    width: 80px;
  }
  a{
    color: inherit;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
`;

function About(props) {
  const [data, setData] = useState({});

  useEffect(() => {

    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/about?populate=*`)
      .then(({ data }) => setData(data.data.attributes))
      .catch((error) => console.error(error));
  }, []);

  const timedIntro = data.intro?.replace('...', differenceInYears(new Date(), new Date(2011, 9, 19)));
  if (!Object.keys(data).length) return;
  return (
    <Wrapper id="about">
      <Intro>
            <Image {...data.profile.data.attributes} />
            <div>
              <h2>{data.greeting}</h2>
              <p>
                {timedIntro}
              </p>
            </div>
      </Intro>
      <Contact>
        <div>
          <h2>Hier kunt u mij vinden</h2>
          <h4>Margreth van Es-Kraak</h4>
          Croonenburgh 75<br />
          3261 RE <br />
          Oud-Beijerland
          <h4>Openingstijden</h4>
          <dl>
            {data.openingtimes?.map((times, i) => (
              <Fragment key={i}>
                <dt>{times.day}</dt>
                <dd>
                  {!times.open && "Gesloten"}
                  {times.open && `${times.from.slice(0, 5)} - ${times.until.slice(0, 5)}`}
                </dd>
              </Fragment>
            ))
            }
          </dl>

          <h4>Tel:  <a href="tel:+31613535688">06 135 35 688</a></h4>
          <h4>Email: <a href="mailto:margreth954@msn.com">margreth954@msn.com</a></h4>
        </div>
        <Map />
      </Contact>
    </Wrapper>
  );
}

export default About;