import React from 'react';
import styled from 'styled-components';
import { primary, secondary } from '../theme/Colors';
import { FaFacebookF, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { MdMailOutline, MdOutlinePhoneIphone } from 'react-icons/md';
import { device } from '../theme/Breakpoints';

const Wrapper = styled.footer`
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  background-color: ${secondary};
  border-radius: 90px 0 0 0;
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
  padding: 20px 20px 20px 60px;
  gap:20px;
  color: white; 
 
  @media ${device.laptop}{ 
    height: 160px;
    width: 320px;
    border-radius: 330px 0 0 0;
  padding: 20px 20px 10px;
  }  

  nav{
    display: flex;
    gap: 20px;
    a{
      height: 40px;
      width: 40px;
      border-radius: 40px;
      background-color: ${primary};
      margin: 0;
      padding: 0;
      color: white; 
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .24s;
      svg{
        height: 24px;
        width: 24px;
      }
      &:hover{
        box-shadow: 0 0 0 2px white;
      }
      &:last-child{
        @media ${device.laptop}{
          display: none;
        }
      }
    }
  }
  h4{
    margin: 0;
    display: none;
    @media ${device.laptop}{
      display: block;
    }
    a {
      transition: all .24s;
      display: block;
      box-sizing: border-box;
      padding: 10px 28px;
      border-radius: 100px;
      background-color: ${primary};
      margin-bottom: 16px;
      font-size: 21px;
      color: white;
      text-decoration: none;
      position: relative;
      font-weight: normal;
      &:hover{
        box-shadow: 0 0 0 2px white;
      }
    }
  }
  
`

const getIcon = (name) => {
  switch (name) {
    case 'facebook':
      return <FaFacebookF />
    case 'whatsapp':
      return <FaWhatsapp />
    case 'instagram':
      return <FaInstagram />
    case 'phone':
      return <MdOutlinePhoneIphone />
    default: return false;
  }
}

function Footer({ socials, contact, visible = true }) {
  if(!visible) return;
  return (
    <Wrapper>
      <nav>
        {socials?.map((s, i) => {
          if (!s.visible) return null;
          return (
            <a key={i} target="_blank" rel="noreferrer" href={s.link}>
              {getIcon(s.type) || s.link}
            </a>
          )
        })}
        <a href="mailto:margreth954@msn.com"><MdMailOutline/></a>
      </nav>
      <h4><a href="mailto:margreth954@msn.com">{contact}</a></h4>
    </Wrapper>
  );
}

export default Footer;